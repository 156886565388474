<template>
  <div class="page-not-found">
    <div class="page-not-found__text">
      <Text variant="h1">404</Text>
      <Text variant="h2">Oops, this page<br /> does not exist</Text>
    </div>
    <div class="page-not-found__mobile-banner"></div>
  </div>
</template>

<script>
import Text from '@/components/UIKit/Text/Text'
export default {
  name: 'PageNotFound',
  components: { Text }
}
</script>

<style lang="scss">
.page-not-found {
  position: relative;
  height: calc(100vh - 290px);
  max-height: 1000px;
  background-image: url("../../assets/not-found/page-not-found-banner.svg");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  background-color: white;

  &__text {
    position: absolute;
    left: 50%;
    @include position-align-y-center;

    h1 {
      font-weight: 800;
      font-size: 60px;
      line-height: 120%;

      color: #D48343;
    }
    h2 {
      margin-top: 30px;
      font-weight: 800;
      font-size: 60px;
      line-height: 120%;

      color: #000000;
    }
  }
  @media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {

    &__text {
      h1, h2 {
        font-size: 48px;
      }
    }
    @media screen and (max-width: 1023px) {
      height: 676px;
      padding-top: 40px;

      &__text {
        h1, h2 {
          font-size: 40px;
        }
      }
      &__mobile-banner {
        margin-bottom: 45px;
      }
    }
  }
  @media screen and (min-width: map-get($mobileLayout, "MinWidth")) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: none;

    &__text {
      transform: none;
      position: unset;
      text-align: center;

      h1, h2 {
        font-size: 32px;
      }
      h2 {
        margin-top: 20px;
      }
    }
    &__mobile-banner {
      width: 100%;
      max-width: 387px;
      height: 408px;
      margin-top: 13px;
      background-image: url("../../assets/not-found/page-not-found-banner-mobile.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    @media screen and (max-width: map-get($mobileLayout, "MaxWidth")){
      padding-top: 100px;
      height: 604px;
    }
  }
}
</style>
