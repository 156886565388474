<template>
  <section class="vue-responsive-videobg">
    <video-poster
      v-if="current.poster"
      :poster="current.poster"
    />

    <video-player
      ref="player"
      :src="current.src"
      :muted="muted"
      :loop="loop"
      :preload="preload"
      :plays-when="playsWhen"
      :playback-rate="playbackRate"
      :transition="transition"
      :object-fit="objectFit"
      :class="{
        'is-visible': isVideoReady,
      }"
      @ready="onReady"
      class="video-player"
    />

    <div class="videobg-content">
      <slot />
    </div>
  </section>
</template>

<script>
import {ref} from 'vue';

import props from './core/props'
import VideoPlayer from './components/VideoPlayer.vue'
import VideoPoster from './components/VideoPoster.vue'

import resize from './core/resize'

export default {
  props,
  mixins: [resize],
  components: {
    VideoPlayer,
    VideoPoster,
  },
  setup() {
    const isVideoReady = ref(false);

    const onReady = () => {
      setTimeout(() => {
        isVideoReady.value = true;
      }, 500);
    }

    return {
      isVideoReady,
      onReady,
    }
  }
}
</script>

<style scoped>
.vue-responsive-videobg{
  background: none;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.vue-responsive-videobg .videobg-content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.is-visible {
  opacity: 1 !important;
}

.video-player {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>
