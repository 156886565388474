<template>
  <div
    class="portfolio-tab-item__wrap"
    :style="{
      '--portfolio-tab-item-background': (item.styles || {})['--portfolio-tab-item-background']
    }"
  >
    <div
      class="portfolio-tab-item"
      :class="{
        'portfolio-tab-item__coming-soon': item.comingSoon,
        'portfolio-tab-item__active': active
      }"
      :style="{
        ...(active ? item.styles : item.tab.styles),
        background: item.tab?.styles?.background
      }"
      @click="!active ? $emit('open') : null"
    >
      <div
        class="portfolio-tab-item__header"
        @click.stop="$emit('open')"
      >
        <Icon :name="icon" />
        <Text class="portfolio-tab-item__header__text">{{ item.tab.text }}</Text>
      </div>
      <Accordion>
        <div v-show="active">
          <PortfolioTabItemBody
            :item="item"
            :active="active"
          >
            <template v-slot:description>
              <slot name="description" :description="description" />
            </template>
          </PortfolioTabItemBody>
        </div>
      </Accordion>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion'
import Icon from '@/components/UIKit/Icon'
import Text from '@/components/UIKit/Text/Text'
import PortfolioTabItemBody from '@/views/Portfolio/PortfolioTabItemBody'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import { computed } from 'vue'

export default {
  name: 'PortfolioTabItem',
  components: {
    PortfolioTabItemBody,
    Text,
    Icon,
    Accordion
  },
  emits: ['open'],
  props: {
    item: {
      type: Object
    },
    active: {
      type: Boolean
    }
  },
  setup (props) {
    const { isMobile } = useBreakpointsMedia()

    const icon = computed(() => props.active ? props.item.icon : props.item.tab.icon)

    return {
      icon,
      isMobile
    }
  }
}
</script>

<style lang="scss">
@mixin portfolio-tab-item-shape {
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.05), inset 0px 3px 0px #FFFFFF;
}
@mixin portfolio-tab-item-shape-mobile {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.portfolio-tab-item__wrap {
  @include portfolio-tab-item-shape;
}
.portfolio-tab-item {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: -80px;
  padding-bottom: 80px;
  background-color: #EFEFEF;
  transition: background-color 2s ease;
  @include portfolio-tab-item-shape;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 63px 120px 63px 130px;
    cursor: pointer;
    margin-bottom: 0;
    transition: all 0.2s linear;

    &__text {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      opacity: 1;
      transition: opacity 0.15s linear;
    }
    &-wrap {
      height: 100%;
      padding-bottom: 80px;
    }
  }

  &:hover::before {
    opacity: 0.1;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include portfolio-tab-item-shape;

    background-color: var(--portfolio-tab-item-background);
    transition: opacity 0.5s linear;
    opacity: 0;
  }

  &__active {

    &:hover::before {
      opacity: 1;
    }
    &::before {
      opacity: 1;
    }
    .portfolio-tab-item__header {
      padding: 63px 120px 33px 130px;
      margin-bottom: 30px;
      @media screen and (min-width: 1680px) {
        margin-bottom: 0;
      }

      &__text {
        opacity: 0;
        user-select: none;
      }
    }
  }

  &__wrap {
    &:last-of-type {

      .portfolio-tab-item {
        padding-bottom: 63px;

        &__header {
          padding-bottom: 0;
        }
      }
    }
  }
  &__coming-soon {
    background-color: #E3E3E3;

    .portfolio-tab-item__header {
      &__text {
        display: block;
        background: #CCCCCC;
        border-radius: 4px;
        opacity: 0.3;
        padding: 7px 15px;
      }
    }
  }

  @media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {
    @media screen and (min-width: 1025px) {
      &__header {
        padding: 51px 100px 55px 100px;
      }
      &__active {
        .portfolio-tab-item__header {
          padding: 51px 0 55px 0;
          margin: 0 100px 25px 100px;
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }
      }
    }
    @media screen and (max-width: 1024px) {
      &__header {
        flex-direction: column;
        justify-content: center;
        padding: 45px 0 38px 0;

        .icon {
          svg {
            transform: scale(0.75);
          }
        }
        &__text {
          margin-top: 12px;
          font-size: 14px;
        }
      }
      &__active {
        .portfolio-tab-item__header {
          padding: 45px 0 38px 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          &__text {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (min-width: map-get($mobileLayout, "MinWidth")) and (max-width: map-get($mobileLayout, "MaxWidth")) {
    margin-top: -35px;
    padding-bottom: 35px;
    @include portfolio-tab-item-shape-mobile;

    &__header .icon svg {
      transform: scale(0.66);
    }

    &::before {
      @include portfolio-tab-item-shape-mobile;
    }
    &__header {
      flex-direction: column;
      justify-content: center;
      padding: 32px 0 24px 0;

      &__text {
        font-size: 14px;
      }
      .icon {
        margin-bottom: 12px;
      }
    }
    &__active {
      .portfolio-tab-item__header {
      padding: 32px 0 24px 0;
      margin-bottom: -10px;
        &__text {
          display: block;
          opacity: 1;
        }
      }
    }
    &__coming-soon {
      .portfolio-tab-item__header {
        &__text {
          background: unset;
          padding: 0;
          font-size: 12px;
        }
      }
    }
    &__wrap {
      &:last-of-type {

        .portfolio-tab-item {
          padding-bottom: 27px;
        }
      }
    }
  }
}
</style>
