<template>
    <transition :name="transition">
        <div class="video-wrapper" >
            <video
                ref="video"
                autoplay
                playsinline
                :muted="muted"
                :loop="loop"
                :preload="preload"
                :style="styleObject"
            >
                <source
                    :src="src"
                    :type="getMediaType(src)"
                >
            </video>
        </div>
    </transition>
</template>

<script>
import props from '../core/playerProps';

export default {
    props,
    emits: ['ready'],
    data() {
        return {
            readyState: 0,
            isVideoReady: false,
            timerId: null,
        };
    },
    computed: {
        styleObject() {
            return {
                objectFit: Object.entries(this.objectFit)?.length ? this.objectFit : {},
            };
        },
    },
    watch: {
        readyState(val) {
            if (val === 4) {
                this.$emit('ready');
            }
        }
    },
    methods: {
        getMediaType(src) {
            return `video/${src.split('.').pop().split(/[?#]/)[0]}`;
        },
        setPlaybackRate() {
            this.$refs.video.playbackRate = this.playbackRate;
            this.$refs.video.defaultPlaybackRate = this.playbackRate;
        },
        onCanPlay() {
            this.setPlaybackRate();
            this.$refs.video.play();
            this.readyState = this.$refs.video?.readyState
        },
        checkReadyState() {
            this.isVideoReady = this.$refs.video?.readyState === 4;
            console.log(this.isVideoReady ? 'ready' : 'not ready');
            if (this.isVideoReady) {
                clearInterval(this.timerId);
                this.onCanPlay();
            }
        }
    },
    mounted() {
        this.timerId = setInterval(this.checkReadyState, 500);
    }
};
</script>

<style scoped>
    .video-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: absolute;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        overflow: hidden;
        z-index: 0;
    }

    .fade{
        backface-visibility: hidden;
    }
    .fade-enter-active{
        transition: opacity 1s;
    }
    .fade-leave-active{
        transition: opacity 1s;
    }

    .fade-enter{
        opacity: 0;
    }
    .fade-leave-to{
        opacity: 0;
    }
    video {
        visibility: visible;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
    }
</style>
