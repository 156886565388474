
export default {
  namespaced: true,
  state: {
    isShowMenu: false
  },
  mutations: {
    SET_IS_SHOW_MENU (state, payload) {
      state.isShowMenu = payload
    }
  },
  getters: {
    getIsShowMenu (state) {
      return state.isShowMenu
    }
  }
}
