import {
  createRouter,
  createWebHistory
} from 'vue-router'
import {
  ROUTES_NAMES,
  ROUTES_PATHS
} from '@/common/routes'
import PageNotFound from '@/views/PageNotFound/PageNotFound'
import About from '@/views/About/About'
import Portfolio from '@/views/Portfolio/Portfolio'

const routes = [
  {
    path: ROUTES_PATHS.PAGE_NOT_FOUND,
    name: ROUTES_NAMES.PAGE_NOT_FOUND,
    component: PageNotFound
  },
  {
    path: ROUTES_PATHS.ABOUT,
    name: ROUTES_NAMES.ABOUT,
    component: About
  },
  {
    path: ROUTES_PATHS.PORTFOLIO,
    name: ROUTES_NAMES.PORTFOLIO,
    component: Portfolio
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
