<template>
  <div class="slider__wrap">
    <div ref="slider" class="slider">
      <div
        v-for="(item, i) in data"
        :key="item.key"
        class="slider__item"
        :class="{
          [`slider__item-${i + 1}`]: i + 1
        }"
      >
        <slot :item="item" :active="active === i" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { Swipe } from '@/utils/Swipe'

export default {
  name: 'Slider',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const slider = ref()
    const swiper = ref()
    const active = ref(2)

    const items = computed(() => {
      return props.data
    })

    onMounted(() => {
      useControls()
    })

    // Update the current order of the sliderItems and gallery
    const setCurrentState = (direction) => {
      if (direction === 'p') {
        items.value.unshift(items.value.pop())
      } else {
        items.value.push(items.value.shift())
      }
    }

    // Add a click event listener to trigger setCurrentState method to rearrange carousel
    const useControls = () => {
      swiper.value = new Swipe(slider.value)

      swiper.value.onLeft(() => {
        setCurrentState('n')
      })
      swiper.value.onRight(() => {
        setCurrentState('p')
      })
      swiper.value.run()
    }

    return {
      active,
      slider,
      setCurrentState
    }
  }
}
</script>

<style lang="scss">
.slider {
  align-items: center;
  display: flex;
  height: 312px;
  margin: 0 auto;
  position: relative;
  perspective: 1000px;
  overflow: hidden;

  &__wrap {
    position: relative;
    width: 100%;

    .fake-scroll {
      display: none;
    }
  }

  &__item {
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    z-index: 0;

    &-1, &-5 {
      opacity: 0.4;
      transform: translateX(-50%) translateZ(-150px);
    }
    &-2, &-4 {
      transform: translateX(-50%) translateZ(-100px);
      opacity: 1;
      z-index: 1;
    }

    &-1 {
      left: 15%;
    }

    &-2 {
      left: 30%;
    }

    &-3 {
      opacity: 1;
      left: 50%;
      transform: translateX(-50%) translateZ(0px);
      z-index: 2;
    }

    &-4 {
      left: 70%;
    }

    &-5 {
      left: 85%;
    }
  }

  @media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {
    &__item {
      &-1 {
        transform: translateX(-61%) translateZ(-150px);
      }
      &-2 {
        transform: translateX(-34%) translateZ(-50px);
      }
      &-4 {
        transform: translateX(-66%) translateZ(-50px);
      }
      &-5 {
        transform: translateX(-39%) translateZ(-150px);
      }
      &-1, &-5 {
        opacity: 1;
      }
    }
    &__wrap {
      .fake-scroll {
        display: block;
      }
    }
  }
}
</style>
