<template>
  <AppLayout />
</template>

<style lang="scss">

</style>
<script>
import AppLayout from '@/layouts/AppLayout'
import { onMounted } from 'vue'
import { imagesPreloader } from '@/utils/imagesPreloader/imagesPreloader'
export default {
  components: {
    AppLayout
  },
  setup () {
    imagesPreloader()
    onMounted(() => {
      document.title = 'Feral'
    })
  }
}
</script>
