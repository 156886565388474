<template>
  <button
    v-bind="$attrs"
    class="button"
    :style="{
      '--button-background-color': StyleVariables[type],
      '--button-color': StyleVariables[`t${type}`],
      '--button-border-color': StyleVariables[`b${type}`],
      '--font-size': fontSize
    }"
  >
    <slot />
  </button>
</template>

<script>
import StyleVariables from '@/styles/variables.scss'

export default {
  name: 'Button',
  inheritAttrs: true,
  props: {
    buttonType: {
      type: String
    },
    type: {
      type: String,
      default: 'default'
    },
    defaultPadding: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '16px'
    }
  },
  setup () {
    return {
      StyleVariables
    }
  }
}
</script>

<style lang="scss">
$buttonBackground: var(--button-background-color);
$buttonColor: var(--button-color);
$buttonBorderColor: var(--button-border-color);
$buttonFontSize: var(--button-font-size);

.button {
  padding: 10px 22px;
  background: $buttonBackground;
  color: $buttonColor;
  border-radius: 3px;
  border: 1px solid $buttonBorderColor;
  font-size: $buttonFontSize;
  cursor: pointer;
}
</style>
