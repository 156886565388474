export const ROUTES_NAMES = {
  PAGE_NOT_FOUND: 'page-not-found',
  ABOUT: 'about',
  PORTFOLIO: 'portfolio'
}

export const ROUTES_PATHS = {
  PAGE_NOT_FOUND: '/:catchAll(.*)',
  ABOUT: '/',
  PORTFOLIO: '/portfolio'
}
