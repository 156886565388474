<template>
  <div class="about-slider">
    <Slider
      v-if="isMobile || isTablet"
      :active="activeItem"
      :data="repertoireList"
    >
      <template #default="{ item, active }">
        <AboutRepertoireSliderItem
          :item="item"
          :active="active"
        />
      </template>
    </Slider>
    <div class="about-slider-row">
      <div
        v-for="item in repertoireList"
        :key="item.key"

        class="about-slider-row__item"
      >
        <AboutRepertoireSliderItem
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import AboutRepertoireSliderItem from '@/views/About/AboutRepertoireSliderItem'
import Slider from '@/components/UIKit/Slider/Slider'

export default {
  name: 'AboutSlider',
  components: {
    Slider,
    AboutRepertoireSliderItem
  },
  setup () {
    const { isMobile, isTablet } = useBreakpointsMedia()

    const activeItem = ref('03')
    const repertoireList = reactive([
      {
        key: '01',
        title: 'Blockchain and Cryptocurrency',
        icon: 'blockchain-icon'
      },
      {
        key: '02',
        title: 'Cloud and SaaS',
        icon: 'cloud-icon'
      },
      {
        key: '03',
        title: 'Cyber Security',
        icon: 'security-icon'
      },
      {
        key: '04',
        title: 'Obfuscation',
        icon: 'obfuscation-icon'
      },
      {
        key: '05',
        title: 'Network\nAuditing',
        icon: 'audition-icon'
      }
    ])

    return {
      isMobile,
      isTablet,
      activeItem,
      repertoireList
    }
  }
}
</script>

<style lang="scss">
.about-slider {
  width: 100%;

  .about-slider-row {
    display: flex;
    align-items: center;
    justify-content: center;

    .about-repertoire-slider-item {
      transition: transform 200ms linear;
      &:hover {
        background-color: map-get($colors, "primary");
        transform: translateZ(50px);
      }
    }
    &__item {
      perspective: 1000px;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .slider__wrap {
    display: none;
  }
  @media screen and
  (max-width: map-get($tabletLayout, "MaxWidth")) and
  (min-width: 1280px) {
    margin-top: 60px;
    margin-bottom: 68px;
  }
  @media screen and (max-width: 1279px) {
    max-width: 979px;

    .slider__wrap {
      display: block;
    }
    .about-slider-row {
      display: none;
    }
  }
}
</style>
