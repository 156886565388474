export const technologiesList = {
  golang: {
    title: 'Golang',
    icon: 'golang'
  },
  vuejs: {
    title: '',
    icon: 'vuejs'
  },
  html: {
    title: 'HTML5 / CSS3',
    icon: 'html'
  },
  php: {
    title: 'PHP',
    icon: 'php'
  },
  docker: {
    title: 'Docker',
    icon: 'docker'
  },
  redis: {
    title: 'Redis',
    icon: 'redis'
  },
  postgre: {
    title: 'PostgreSQL',
    icon: 'postgre'
  },
  nodejs: {
    title: 'NodeJS',
    icon: 'nodejs'
  },
  nestjs: {
    title: 'NestJS',
    icon: 'nestjs'
  },
  mongo: {
    title: 'MongoDB',
    icon: 'mongo'
  },
  mongooseim: {
    title: '',
    icon: 'mongooseim'
  },
  erlang: {
    title: '',
    icon: 'erlang'
  },
  ejabberd: {
    title: '',
    icon: 'ejabberd'
  },
  mysql: {
    title: '',
    icon: 'mysql'
  }
}

export const getTechnology = key => {
  return technologiesList[key]
}

export const projectsList = [
  {
    key: 'switch',
    tab: {
      icon: 'white-switch-logo',
      text: 'Crypto Banking',
      styles: {
        background: 'linear-gradient(97.76deg, #BF5D35 0%, #D5823C 56%)',
        color: 'white'
      }
    },
    icon: 'switch-logo',
    title: 'Swiss banking reinvented.<br/>Security by design, absolute.',
    description: 'Comprehensive crypto payment gateway for anyone and everyone. Non-custodial wallet pairing for absolute financial control. Your money, not the banks’.',
    technologies: [
      'nodejs',
      'vuejs',
      'html',
      'php',
      'docker',
      'nestjs',
      'mongo'
    ],
    download: [
      {
        icon: 'apple',
        website: '',
        isDisabled: true
      },
      {
        icon: 'google-play',
        website: '',
        isDisabled: true
      }
    ],
    website: 'switch.to',
    styles: {
      '--portfolio-tab-item-background': '#F8F3ED',
      color: 'black'
    }
  },

  {
    key: 'otr',
    tab: {
      icon: 'otr',
      text: 'Secure Messaging',
      styles: {
        background: 'linear-gradient(97.76deg, rgba(0, 76, 95, 1) 0%, rgba(0, 88, 111, 1) 56%)',
        color: 'white'
      }
    },
    icon: 'otr-black',
    title: 'Secure communication over XMPP, free for all.',
    description: 'Based on MongooseIM, a scalable XMPP server. Inspired by the Off-The-Record project, a non-commercial service with enforced encryption and randomized aliases.',
    technologies: [
      'ejabberd',
      'erlang',
      'vuejs',
      'html',
      'mysql'
    ],
    website: 'otr.chat',
    styles: {
      '--portfolio-tab-item-background': '#E6ECF2',
      color: 'black'
    },
    hasDescriptionSlot: true
  },
  {
    key: 'chainless',
    tab: {
      icon: 'chainless',
      text: 'Coming soon'
    },
    comingSoon: true,
    title: '',
    description: '',
    technologies: [],
    download: null,
    website: '',
    img: '',
    background: ''
  },
  {
    key: 'void',
    tab: {
      icon: 'void',
      text: 'Coming soon'
    },
    comingSoon: true,
    title: '',
    description: '',
    technologies: [],
    download: null,
    website: '',
    img: '',
    background: ''
  },
  {
    key: 'gocode',
    tab: {
      icon: 'gocode',
      text: 'Coming soon'
    },
    comingSoon: true,
    title: '',
    description: '',
    technologies: [],
    download: null,
    website: '',
    img: '',
    background: ''
  },
  {
    key: 'iani',
    tab: {
      icon: 'iani',
      text: 'Coming soon'
    },
    comingSoon: true,
    title: '',
    description: '',
    technologies: [],
    download: null,
    website: '',
    img: '',
    background: ''
  }
]
