<template>
  <div
    class="about-repertoire-slider-item"
    :class="{
      'about-repertoire-slider-item__active': active,
    }"
  >
    <Text class="about-repertoire-slider-item__index">
      {{ item.key }}
    </Text>
    <div class="about-repertoire-slider-item__icon">
      <Icon
        :name="item.icon"
      />
    </div>
    <Text class="about-repertoire-slider-item__title">
      {{ item.title }}
    </Text>
  </div>
</template>

<script>
import Text from '@/components/UIKit/Text/Text'
import Icon from '@/components/UIKit/Icon'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
export default {
  name: 'AboutRepertoireSliderItem',
  components: {
    Icon,
    Text
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { isMobile, isTablet } = useBreakpointsMedia()

    return {
      isMobile,
      isTablet
    }
  }
}
</script>

<style lang="scss">
.about-repertoire-slider-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 220px;
  height: 320px;
  background-color: white;
  padding: 54px 10px;

  &__index {
    position: absolute;
    top: 36px;
    left: 30px;
    font-size: 18px;
    line-height: 140%;
    color: #2B2B2F;
    opacity: 0.5;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    margin-bottom: 25px;
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 150px;
    margin-top: 20px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    white-space: break-spaces;
  }
  @media screen and
  (min-width: map-get($mobileLayout, "MinWidth")) and
  (max-width: map-get($tabletLayout, "MaxWidth")) {
    width: 210px;
    height: 280px;
    padding-bottom: 45px;

    &__index {
      top: 20px;
      left: 23px;
    }

    &__icon {

      .icon {
        --icon-height: unset !important;
        --icon-width: unset !important;
      }
    }

    @media screen and (max-width: 1279px) {
      width: 160px;
      height: 210px;
      padding-bottom: 51px;
      border-radius: 4px;
      transition: background-color 200ms linear;

      &__active {
        background-color: map-get($colors, "primary");
      }
      &__index {
        top: 28px;
        left: 20px;
        font-size: 12px;
      }
      &__icon {
        height: 20%;

        .icon {
          --icon-height: 56px !important;
          --icon-width: 49px !important;
        }
      }
      &__title {
        height: 20px;
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
}
</style>
