export const IMAGES_LIST = {
  about: [
    {
      url: require('../assets/about/operandy-outsourcing-background-mobile.svg')
    },
    {
      url: require('../assets/about/operandy-management-background-mobile.svg')
    },
    {
      url: require('../assets/about/operandy-partnership-background-mobile.svg')
    },
    {
      url: require('../assets/about/operandy-outsourcing-background-tablet.svg')
    },
    {
      url: require('../assets/about/operandy-management-background.svg')
    },
    {
      url: require('../assets/about/operandy-outsourcing-background.svg')
    },
    {
      url: require('../assets/about/operandy-title-mobile-background.svg')
    }
  ],
  portfolio: [
    {
      url: require('../assets/projects/amhora.png')
    },
    {
      url: require('../assets/projects/switch.png')
    },
    {
      url: require('../assets/projects/otr.png')
    },
    {
      url: require('../assets/portfolio/portfolio-quote-background.jpeg')
    },
    {
      url: require('../assets/portfolio/portfolio-pioneering-background-mobile.svg')
    },
    {
      url: require('../assets/portfolio/portfolio-pioneering-background-tablet.svg')
    },
    {
      url: require('../assets/portfolio/portfolio-pioneering-background-tablet-1280.svg')
    },
    {
      url: require('../assets/portfolio/portfolio-pioneering-background.svg')
    }
  ],
  pageNotFound: [
    {
      url: require('../assets/not-found/page-not-found-banner.svg')
    },
    {
      url: require('../assets/not-found/page-not-found-banner-mobile.svg')
    }
  ]
}
