import { IMAGES_LIST } from '@/common/images'
import { useRoute } from 'vue-router'
import { ROUTES_NAMES } from '@/common/routes'

export const loadImage = url => {
  const img = new Image()
  img.src = url
}

export const loadImagesStack = (arr = []) => {
  arr.forEach(item => {
    loadImage(item.url)
  })
}

export const imagesPreloader = () => {
  const route = useRoute()

  if (route.name === ROUTES_NAMES.ABOUT) {
    loadImagesStack(IMAGES_LIST.about)
    loadImagesStack(IMAGES_LIST.portfolio)
    loadImagesStack(IMAGES_LIST.pageNotFound)
  } else if (route.name === ROUTES_NAMES.PORTFOLIO) {
    loadImagesStack(IMAGES_LIST.portfolio)
    loadImagesStack(IMAGES_LIST.about)
    loadImagesStack(IMAGES_LIST.pageNotFound)
  } else {
    loadImagesStack(IMAGES_LIST.pageNotFound)
    loadImagesStack(IMAGES_LIST.about)
    loadImagesStack(IMAGES_LIST.portfolio)
  }
}
