<template>
    <div class="about">
      <div class="about__head-banner">
        <VideoBackground
          :src="aboutBackgroundVideo || aboutBackgroundImg"
          :poster="aboutBackgroundImg"
        />
        <div class="about__head-banner__gradient"/>
        <Text color="white">
          <Text
            variant="h1"
            weight="800"
            size="80px"
            line-height="120%"
            class="about__head-banner__title"
          >
            We develop<br/>
            cutting-edge<br v-if="isMobile" />
            futuristic<br v-if="!isMobile"/>
            cyber<br v-if="isMobile" />
            projects
          </Text>
          <Text
            variant="p"
            weight="700"
            size="30px"
            line-height="120%"
            mt="82px"
            class="about__head-banner__description"
          >
            Witness unprecedented<br/>
            attention to detail.
            <br/><br/><br/>
            kill competition.
          </Text>
        </Text>
      </div>
      <div class="about__repertoire">
        <div class="about__repertoire__description">
          <Text
            variant="h2"
            :size="'60px'"
            weight="800"
            line-height="95px"
            class="about__repertoire__description-title"
          >
            Repertoire
          </Text>
          <Text
            variant="p"
            :line-height="'150%'"
            class="about__repertoire__description-body"
          >
            <Text>
              All the way from the blueprints to the final amalgamation.
            </Text>
            Our products stand dictum to our philosophy which is why our relationship goes beyond just development and into research, maintenance, marketing, and customer support.
          </Text>
        </div>
        <AboutSlider />
        <div class="about__repertoire__description-bottom">
            For bespoke ideas befitting our motto, we engage projects with more than just a developers' stance.
        </div>
      </div>
      <AboutOperandiSection />
      <div class="about__contact-br" />
      <div class="about__contact">
        <Text class="about__contact-title">
          Let’s talk business.
        </Text>
        <div
          class="about__contact-email"
          @click="writeEmail('hello@feral.com')"
        >
          <Icon
            :key="isRealTablet"
            name="about-mail"
            :width="isRealTablet ? '20px' : isMobile ? '16px' : '34px'"
            :height="isRealTablet ? '20px' : isMobile ? '16px' : '34px'"
          />
          <Text>
            hello@feral.com
          </Text>
        </div>
        <div class="about__contact-hr" />
        <Text variant="p" class="about__contact-description">
          Request a free consultation by sending us an email with your ideas/prototype and requirements. We take both private and government projects - subject to our discretion.
        </Text>
      </div>
    </div>
</template>

<script>
import Text from '@/components/UIKit/Text/Text'
import AboutSlider from '@/views/About/AboutSlider'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import AboutOperandiSection from '@/views/About/AboutOperandiSection'
import Icon from '@/components/UIKit/Icon'
import VideoBackground from '@/components/VideoBackground'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { writeEmail } from '@/utils/writeEmail'

export default {
  name: 'About',
  components: {
    Text,
    VideoBackground,
    Icon,
    AboutOperandiSection,
    AboutSlider
  },
  setup () {
    const store = useStore()
    const { isMobile, isTablet, isRealTablet } = useBreakpointsMedia()

    const aboutBackgroundVideo = computed(() => {
      return store.getters.getAboutBackgroundVideo
    })
    const aboutBackgroundImg = computed(() => {
      return store.getters.getAboutBackgroundImg
    })

    onMounted(() => {
      store.commit('SET_ABOUT_BACKGROUND_VIDEO')
    })

    return {
      isMobile,
      isTablet,
      isRealTablet,
      aboutBackgroundImg,
      aboutBackgroundVideo,
      writeEmail
    }
  }
}
</script>

<style lang="scss">
.about {
  background-color: white;
  overflow: hidden;

  border-radius: 0 0 40px 40px;
  transition: border-radius 0.2s linear;

  @media screen and (max-width: 1280px) {
    border-radius: 0;
  }

  &__head-banner {
    position: relative;
    padding: 170px 0 230px 160px;
    background: transparent;
    user-select: none;

    &__gradient {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
      background: linear-gradient(70.79deg, rgba(0, 0, 0, 0.75) 13.81%, rgba(0, 0, 0, 0) 59.19%, rgba(0, 0, 0, 0) 91.93%);
    }
    .text {
      position: relative;
      z-index: 1;
    }
    .vue-responsive-videobg {
      position: absolute !important;
      top: -1px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }
  &__repertoire {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -8% 2% 0 2%;
    padding-top: 5.5%;
    padding-bottom: 6%;
    background-color: #EDEDED;
    border-radius: 20px;

    &__description {
      max-width: 1014px;
      text-align: center;
      margin-bottom: 80px;

      &-bottom {
        max-width: 700px;
        margin-top: 94px;
        text-align: center;
        font-size: 24px;
        line-height: 150%;
        opacity: 0.4;
      }

      &-title {
        margin-bottom: 56px;
      }

      &-body {
        font-size: 24px;
        line-height: 150%;
      }
    }
  }
  &__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #EDEDED;
    padding: 190px 380px 236px;
    @media screen and (max-width: 1680px) {
      padding-left: 180px;
      padding-right: 180px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1680px) {
      padding: 190px 110px 196px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 64px 120px 42px;
    }

    &-title {
      font-weight: 700;
      font-size: 60px;
      line-height: 150%;
      margin-bottom: 40px;
    }
    &-email {
      display: flex;
      align-items: center;
      margin-bottom: 80px;
      font-weight: 700;
      font-size: 40px;
      line-height: 150%;

      color: #D48343;
      cursor: pointer;

      .icon {
        margin-top: -3px;
        margin-right: 24px;
      }
    }
    &-hr {
      width: 100%;
      height: 1px;
      margin-bottom: 80px;
      background: #CCCCCC;
      border-radius: 15px;
    }
    &-description {
      font-size: 26px;
      line-height: 140%;

      text-align: center;
    }
  }
}

@media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {
  .about {
    &__head-banner {
      padding: 120px 0 17% 100px;
      background-position: top;

      &__title {
        font-size: 60px;
        line-height: 120%;
      }
      &__description {
        font-size: 22px;
        line-height: 120%;
      }
    }
    &__repertoire {
      padding-top: 80px;
      padding-bottom: 80px;

      &__description {
        max-width: 908px;
        margin-bottom: 0;

        &-title {
          font-size: 45px;
          line-height: 120%;
          margin-bottom: 43px;
        }
        &-body {
          font-size: 20px;
        }
        &-bottom {
          margin-top: 0;
          font-size: 20px;
        }
      }
    }
    &__contact {
      &-hr {
        margin-bottom: 60px;
      }
      &-description {
        font-size: 19px;
      }
    }
  }

  @media screen and (max-width: 1279px) {
    .about {
      &__head-banner {
        padding-top: 110px;
        padding-left: 80px;
      }

      &__repertoire {
        padding-top: 70px;
        padding-bottom: 40px;

        &__description {
          max-width: 744px;

          &-title {
            font-size: 52px;
            margin-bottom: 34px;
          }

          &-body {
            font-size: 16px;
          }

          &-bottom {
            margin-top: 0;
            font-size: 16px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .about {
      &__head-banner {
        padding-top: 80px;
        padding-left: 65px;
      }
      &__repertoire {
        padding-top: 52px;
        padding-bottom: 71px;

        &__description {
          max-width: 546px;

          &-title {
            font-size: 40px;
            margin-bottom: 32px;
          }
        }
      }
      &__contact {
        &-title {
          font-size: 32px;
          margin-bottom: 20px;
        }
        &-email {
          font-size: 20px;
          margin-bottom: 44px;
          .icon {
            margin-right: 16px;
          }
        }
        &-hr {
          margin-bottom: 42px;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($mobileLayout, "MinWidth")) and (max-width: map-get($mobileLayout, "MaxWidth")) {
  .about {
    padding-bottom: 0;

    &__head-banner {
      padding: 103px 40px 100px 40px;

      .vue-responsive-videobg {
        left: -129px;
        width: 140% !important;
      }
      &__gradient {
        background: #000000;
        opacity: 0.4;
      }
      &__title {
        font-size: 40px;
      }
      &__description {
        margin-top: 68px;
        font-size: 22px;
      }
    }
    &__repertoire {
      margin-top: -50px;
      padding-top: 40px;

      &__description {
        padding: 0 36px;
        margin-bottom: 0;

        &-bottom {
          margin-top: 0;
          font-size: 16px;
          max-width: 320px;
        }
        &-title {
          font-size: 32px;
          line-height: 120%;
          margin-bottom: 33px;
        }
        &-body {
          font-size: 16px;
        }
      }
    }
    &__contact {
      margin: 0;
      padding: 70px 40px 60px;
      border-radius: 0;

      &-title {
        font-size: 32px;
        margin-bottom: 25px;
      }
      &-br {
        height: 4px;
        background: #000000;
      }
      &-email {
        margin-bottom: 50px;
        font-size: 20px;

        .icon {
          margin-right: 12px;
        }
      }
      &-hr {
        margin-bottom: 30px;
      }
      &-description {
        font-size: 16px;
      }
    }
  }
}
</style>
