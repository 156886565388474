<template>
  <div
    v-html="icons[name]"
    class="icon"
    :style="styles"
  />
</template>

<script>
import icons from '@/assets/icons'
import { reactive } from 'vue'

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      validate (val) {
        const keys = Object.keys(icons)
        return keys.includes(val)
      }
    },
    width: {
      type: [String, Number],
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    },
    display: {
      type: String,
      default: 'block'
    },
    opacity: {
      type: [String, Number],
      default: 'unset'
    },
    fill: {
      type: String,
      default: ''
    },
    stroke: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const setStyles = () => {
      const styles = {}
      if (props.height) {
        styles['--icon-height'] = props.height
      }
      if (props.height) {
        styles['--icon-width'] = props.width
      }
      if (props.display) {
        styles['--icon-display'] = props.display
      }
      if (props.opacity) {
        styles['--icon-opacity'] = props.opacity
      }
      if (props.fill) {
        styles['--icon-opacity'] = props.fill
      }
      if (props.stroke) {
        styles['--icon-opacity'] = props.stroke
      }
      return styles
    }
    const styles = reactive(setStyles())
    return {
      icons,
      styles
    }
  }
}
</script>

<style lang="scss">
  .icon {

    svg {
      display: var(--icon-display);
      height: var(--icon-height);
      width: var(--icon-width);
      opacity: var(--icon-opacity);
    }
  }

</style>
