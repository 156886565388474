<template>
  <component :is="layout" />
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, defineAsyncComponent } from 'vue'

export default {
  name: 'AppLayout',
  components: {
    DefaultLayout: defineAsyncComponent(() => import('@/layouts/DefaultLayout'))
  },
  setup () {
    const route = useRoute()

    const layout = computed(() => {
      if (route.meta?.layout) {
        return route.meta.layout
      }

      return 'DefaultLayout'
    })

    return { layout }
  }
}
</script>
