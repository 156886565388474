import widthVariables from '@/styles/breakpoints.scss'

export const MEDIA = {
  mobile: {
    maxWidth: widthVariables.mobileMaxWidth.replace('px', ''),
    minWidth: widthVariables.mobileMinWidth.replace('px', ''),
    headerHeight: widthVariables.mobileHeaderHeight.replace('px', '')
  },
  tablet: {
    maxWidth: widthVariables.tabletMaxWidth.replace('px', ''),
    realMaxWidth: widthVariables.tabletRealMaxWidth.replace('px', ''),
    minWidth: widthVariables.tabletMinWidth.replace('px', ''),
    headerHeight: widthVariables.tabletHeaderHeight.replace('px', '')
  },
  desktop: {
    // maxWidth: widthVariables.desktopMaxWidth.replace('px', ''),
    minWidth: widthVariables.desktopMinWidth.replace('px', ''),
    headerHeight: widthVariables.desktopHeaderHeight.replace('px', '')
  }
  // desktop4K: {
  //   minWidth: widthVariables.desktop4KMinWidth.replace('px', ''),
  //   headerHeight: widthVariables.desktop4KHeaderHeight.replace('px', '')
  // }
}
