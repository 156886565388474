<template>
  <div class="portfolio-tabs">
    <PortfolioTabItem
      v-for="item in projectsList"
      :class="item.key"
      :key="item.key"
      :item="item"
      :active="activeTab === item.key && !item.comingSoon"
      @open="openTab(item)"
    >
      <template v-slot:description v-if="item.key === 'otr'">
        <span style="font-size: 18px;">
          Based on ejabberd, a scalable XMPP server. Inspired by
          <a class="medium-link" target="_blank" href="https://otr.cypherpunks.ca/">Off-The-Record project</a>, a non-commercial service with anonymous registration and randomized aliases.
        </span>
      </template>
    </PortfolioTabItem>
  </div>
</template>

<script>
import { ref } from 'vue'

import PortfolioTabItem from './PortfolioTabItem'

import { projectsList } from './data'

export default {
  name: 'PortfolioTabs',
  components: {
    PortfolioTabItem
  },
  setup () {
    const activeTab = ref(null)

    const openTab = item => {
      activeTab.value = activeTab.value === item.key ? null : item.key
    }

    return {
      activeTab,
      projectsList,
      openTab
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-tabs {
  .amhora {
    @media screen and (min-width: 1680px) {
      ::v-deep(.project-info) {
        width: 80%;
        margin-right: 290px;
      }
      ::v-deep(.picture) {
        height: 790px;
        margin-bottom: 40px;
      }
    }
    @media screen and (max-width: 1680px) {
      ::v-deep(.project-info) {
        margin-right: 240px;
      }
      ::v-deep(.picture) {
        height: 640px;
        margin-bottom: 40px;
      }
    }
    @media screen and (max-width: 1280px) {
      ::v-deep(.project-info) {
        width: 70%;
        margin-right: 120px;
      }
      ::v-deep(.picture) {
        height: 640px;
        margin-bottom: 40px;
      }
    }
    @media screen and (max-width: 1024px) {
      ::v-deep(.picture) {
        height: 50vw;
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 768px) {
      ::v-deep(.project-info) {
        width: 100%;
      }
      ::v-deep(.picture) {
        height: 70vw;
      }
    }
  }
}

.medium-link {
  width: 190px;
  color: #737679;
  text-decoration: none;
  display: inline-block;
  font-weight: normal;
  &:hover, &:focus, &:active {
    color: #000;
    text-decoration: none;
    font-weight: 500;
  }
}
</style>
