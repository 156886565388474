import { createStore } from 'vuex'
import headerModule from '@/store/headerModule'

export default createStore({
  state: {
    aboutBackgroundVideo: null,
    aboutBackgroundImg: require('../assets/about/about-head-banner.jpg')
  },
  mutations: {
    SET_ABOUT_BACKGROUND_VIDEO (state) {
      state.aboutBackgroundVideo = require('../assets/about/about-head-banner.mp4')
    }
  },
  actions: {
  },
  getters: {
    getAboutBackgroundVideo (state) {
      return state.aboutBackgroundVideo
    },
    getAboutBackgroundImg (state) {
      return state.aboutBackgroundImg
    }
  },
  modules: {
    headerModule
  }
})
