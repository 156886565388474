<template>
  <div class="portfolio">
    <div class="portfolio__main">
      <div class="portfolio__main-block portfolio__main__pioneering">
        <Text variant="h2" class="portfolio__main__pioneering__title">
          Pioneering<br />
          Privacy
        </Text>
        <Text variant="p" class="portfolio__main__pioneering__description">
          A fundamental human right, at core of the projects we undertake. Internet is freedom.
        </Text>
      </div>
      <div class="portfolio__main-block portfolio__main__quote">
        <Icon name="quote" />
        <Text class="portfolio__main__quote__description">
          Arguing that you don't care about the right to privacy because you have nothing to hide is no different than saying you don't care about free speech because you have nothing to say.
        </Text>
        <Text class="portfolio__main__quote__author">Edward Snowden</Text>
      </div>
    </div>
    <PortfolioTabs />
  </div>
</template>

<script>

import Text from '@/components/UIKit/Text/Text'
import Icon from '@/components/UIKit/Icon'
import PortfolioTabs from '@/views/Portfolio/PortfolioTabs'
export default {
  name: 'Portfolio',
  components: { PortfolioTabs, Icon, Text }
}
</script>

<style lang="scss">
.portfolio {

  &__main {
    display: flex;
    height: 1041px;

    &-block {
      width: 50%;
    }
    &__pioneering {
      padding: 180px 80px;
      background-color: map-get($colors, "primary");
      background-image: url("../../assets/portfolio/portfolio-pioneering-background.svg");
      background-size: cover;
      background-position: left;

      &__title {
        margin-bottom: 100px;
        font-weight: 800;
        font-size: 80px;
        line-height: 100%;
      }
      &__description {
        max-width: 400px;
        font-weight: 800;
        font-size: 30px;
        line-height: 120%;
      }
    }
    &__quote {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 187px 120px;
      color: white;
      background-color: black;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 51%, #000000 100.63%), url("../../assets/portfolio/portfolio-quote-background.jpeg");
      background-size: cover;
      background-position: right;
      background-repeat: no-repeat;

      .icon {
        margin-bottom: 28px;
      }
      &__description {
        margin-bottom: 28px;
        font-weight: 500;
        font-size: 28px;
        line-height: 150%;
        color: #F1F1F1;
      }
      &__author {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #F1F1F1;
        opacity: 0.4;
      }
    }
  }

  @media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {
    &__main {
      height: 820px;

      &__pioneering {
        padding: 145px 80px;

        &__title {
          font-size: 60px;
          margin-bottom: 60px;
        }
        &__description {
          max-width: 350px;
          font-size: 22px;
        }
      }
      &__quote {
        padding: 106px 40px;

        &__description {
          font-size: 20px;
          margin-bottom: 21px;
        }
      }
    }
    @media screen and (max-width: 1279px) {
      &__main {
        &__pioneering {
          padding: 90px 60px;
          background-image: url("../../assets/portfolio/portfolio-pioneering-background-tablet-1280.svg");
        }
      }
    }
    @media screen and (max-width: 1023px) {
      &__main {
        height: 800px;

        &__pioneering {
          padding: 90px 60px;
          background-image: url("../../assets/portfolio/portfolio-pioneering-background-tablet.svg");
        }
        &__quote {
          background-position: right -57px bottom;
        }
      }
    }
  }
  @media screen and (min-width: map-get($mobileLayout, "MinWidth")) and (max-width: map-get($mobileLayout, "MaxWidth")) {
    &__main {
      height: auto;
      flex-direction: column;

      &__pioneering, &__quote {
        width: 100%;
      }
      &__pioneering {
        height: 480px;
        padding: 100px 24% 100px 20px;
        background-image: url("../../assets/portfolio/portfolio-pioneering-background-mobile.svg");

        &__title {
          font-size: 40px;
          margin-bottom: 30px;
        }
        &__description {
          font-size: 18px;
        }
      }
      &__quote {
        height: 450px;
        padding: 78px 20px 60px;
        background-position: right bottom calc(200px - 53vw);

        &__description {
          font-size: 18px;
          margin-bottom: 12px;
        }
        &__author {
          font-size: 16px;
        }
      }
    }

    @media screen and (min-width: 600px) {
      &__main {
        &__quote {
          background-position: right bottom -300px;
        }
      }
    }
  }
}
</style>
