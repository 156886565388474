import { MEDIA as MEDIA_QUERIES } from '@/plugins/breakpoints/media'
import { computed } from 'vue'
import { useBreakpoints as breakpointsPlugin } from '@/plugins/breakpoints/useBreakpoints'

export const useBreakpoints = breakpointsPlugin

export const useBreakpointsMedia = () => {
  const breakpoints = useBreakpoints({
    mobile: [MEDIA_QUERIES.mobile.minWidth, MEDIA_QUERIES.mobile.maxWidth],
    tablet: [MEDIA_QUERIES.tablet.minWidth, MEDIA_QUERIES.tablet.maxWidth],
    realTablet: [MEDIA_QUERIES.tablet.minWidth, MEDIA_QUERIES.tablet.realMaxWidth],
    desktopStandard: [MEDIA_QUERIES.desktop.minWidth, MEDIA_QUERIES.desktop.maxWidth],
    // desktop4K: [MEDIA_QUERIES.desktop4K.minWidth],
    desktop: [MEDIA_QUERIES.desktop.minWidth]
  })

  // const isDesktop4K = computed(() => breakpoints.desktop4K.matches)
  const isTablet = computed(() => breakpoints.tablet.matches)
  const isRealTablet = computed(() => breakpoints.realTablet.matches)
  const isDesktopStandard = computed(() => breakpoints.desktopStandard.matches)
  const isDesktop = computed(() => breakpoints.desktop.matches)
  const isMobile = computed(() => breakpoints.mobile.matches)

  return {
    breakpoints,
    isMobile,
    isTablet,
    isRealTablet,
    isDesktop,
    // isDesktop4K,
    isDesktopStandard
  }
}

export const MEDIA = MEDIA_QUERIES
