<template>
  <component
    :is="variant"
    class="text"
    :class="{
      ['text__' + type]: type,
      'text__uppercase-first': uppercaseFirstLetter,
       ...classes,
    }"
    :style="{
      '--text-size': size,
      '--text-weight': weight,
      '--text-width': width,
      '--text-line-height':  lineHeight,
      '--text-color':  colorTheme,
      '--text-opacity':  opacity,
      '--text-mt': mt,
      '--text-mr': mr,
      '--text-mb': mb,
      '--text-ml': ml,
    }"
  >
    <slot />
  </component>
</template>

<script>
import { computed } from 'vue'
import StyleVariables from '@/styles/variables.scss'

export default {
  name: 'Text',
  inheritAttrs: true,
  props: {
    variant: {
      type: String,
      default: 'span'
    },
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'inherit'
    },
    weight: {
      type: String,
      default: 'inherit'
    },
    width: {
      type: String,
      default: 'unset'
    },
    lineHeight: {
      type: String,
      default: 'inherit'
    },
    mt: {
      type: String,
      default: '0'
    },
    mr: {
      type: String,
      default: '0'
    },
    mb: {
      type: String,
      default: '0'
    },
    ml: {
      type: String,
      default: '0'
    },
    color: {
      type: String,
      default: 'inherit'
    },
    theme: {
      type: String,
      default: ''
    },
    opacity: {
      type: [String, Number],
      default: '1'
    },
    uppercaseFirstLetter: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { attrs }) {
    const classes = computed(() => {
      return Object.keys(attrs).reduce((acc, item) => {
        return {
          ...acc,
          ['text__' + item]: true
        }
      }, {})
    })
    const colorTheme = computed(() => StyleVariables[props.theme] || props.color)
    return {
      classes,
      colorTheme
    }
  }
}
</script>

<style lang="scss">
.text {
  width: var(--text-width);
  font-size: var(--text-size);
  font-weight: var(--text-weight);
  line-height: var(--text-line-height);
  color: var(--text-color);
  margin: var(--text-mt) var(--text-mr) var(--text-mb) var(--text-ml) ;
  opacity: var(--text-opacity);

  &__uppercase-first::first-letter {
    text-transform: uppercase;
  }
  &__muted {
    color: #000000;
    opacity: 0.5;
  }
  &__uppercase {
    text-transform: uppercase;
  }
  &__align-center {
    text-align: center;
  }
  &__lowercase {
    text-transform: lowercase;
  }
}
</style>
