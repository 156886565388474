<template>
  <div class="project">
    <div class="project-info">
      <Text
        variant="h4"
        class="project-title"
        v-html="item.title"
      />
      <Text
        variant="p"
        class="project-description"
      >
        <slot name="description" v-if="item.hasDescriptionSlot">
          {{ item.description }}
        </slot>
        <template v-else>
          {{ item.description }}
        </template>
      </Text>
      <div class="links">
        <Button
          class="link"
          @click="openLink(item.website)"
        >
          <Icon name="open-link" />
          <Text>{{ websiteTitle }}</Text>
        </Button>
      </div>
      <div class="divider" />
      <div v-if="item.technologies" class="technologies">
        <div
          v-for="technology in item.technologies"
          :key="technology"
          class="technologies-item"
        >
          <Icon :name="technologiesList[technology].icon" />
          <Text>{{ technologiesList[technology].title }}</Text>
        </div>
      </div>
    </div>
    <div
      class="picture"
      :style="{ backgroundImage: img }"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue'

import Text from '@/components/UIKit/Text/Text'
import Button from '@/components/UIKit/Button'
import Icon from '@/components/UIKit/Icon'
import { openLink } from '@/utils/openLink'

import { technologiesList } from './data'

export default {
  name: 'PortfolioTabItemBody',
  components: { Icon, Button, Text },
  props: {
    item: {
      type: Object
    },
    active: {
      type: Boolean
    }
  },
  setup (props) {
    const websiteTitle = computed(() => {
      return props.item.website?.replace('http://', '')
    })

    const img = ref('')
    try {
      img.value = require(`../../assets/projects/${props.item.key}.png`)
    } catch (e) {}

    return {
      websiteTitle,
      openLink,
      technologiesList,

      img: `url(${img.value})`
    }
  }
}
</script>

<style lang="scss">
.project {
  display: flex;
  overflow: hidden;
  transition: opacity 700ms linear;

  &-info {
    width: 80%;
    padding-top: 37px;
    margin: 50px 140px 0 116px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  &-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
  }
  &-description {
    margin-top: 30px;
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 150%;
  }
}

.links {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
.link {
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 8px 16px;
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 62px;

  &:first-of-type {
    margin-right: 23px;
  }
  &-download {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    padding: 0;
    border-radius: 50%;

    &:last-of-type {
      .icon {
        margin-left: 5px;
      }
    }
  }
  .text {
    display: block;
    margin-top: 1px;
    margin-left: 10px;
    font-size: 13px;
    line-height: 150%;
  }
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  max-width: 92%;

  &-item {
    display: flex;
    align-items: center;
    margin-right: 33px;
    margin-bottom: 33px;

    .icon {
      margin-right: 7px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.picture {
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;

  width: 100%;
  height: 640px;
  margin-bottom: 90px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #000000;
  opacity: 0.1;
  margin-bottom: 57px;
}

.link-download.is-disabled {
  box-shadow: unset;
  border: unset;
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  &::before {
    content: 'Coming soon';
    position: absolute;
    width: 116px;
    height: 36px;

    background: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 4px;
    transition: all 0.2s;
    bottom: -40px;
    display: none;
    left: calc(50% - 58px);

    display: flex;
    justify-content: center;
    align-items: center;

    visibility: hidden;
    opacity: 0;
    font-size: 14px;
  }
  &:hover {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: 1680px) {
  .project {
    &-info {
      width: 50%;
      border-top: none;
      padding-top: 0;
      margin: 18px 100px 0 100px;
    }
    &-title {
      font-size: 32px;
    }
  }
  .technologies {
    max-width: 100%;
  }
  .picture {
    width: 50%;
    height: 500px;
  }
}

@media (max-width: 1280px) {
  .picture {
    width: 60%;
    height: 500px;
  }
}

@media (max-width: 1024px) {
  .project {
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 24px;
    &-info {
      text-align: center;
      width: 70%;
      max-width: 600px;
      margin: 20px 0 0 0;
      margin-right: 0 !important;
      text-align: center;
    }
    &-description {
      margin-bottom: 40px;
    }
  }
  .links {
    justify-content: center;
    margin-bottom: 40px;
  }
  .technologies {
    justify-content: center;
  }
  .divider {
    margin-bottom: 40px;
  }

  .picture {
    text-align: center;
    height: 40vw;
    width: 80%;
    background-position: center;
    background-size: contain;
    margin-bottom: 20px;
  }

}
@media (max-width: 768px) {
  .project {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0;

    &-info {
      width: 100%;
      padding: 15px 20px;
      margin: 0;
      border-top: unset;
    }
    &-title {
      font-size: 22px;
    }
    &-description {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .links, .divider, .technologies {
    margin-bottom: 30px;
  }
  .technologies {
    max-width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;

    &-item {
      margin-right: 15px;
      margin-bottom: 22px;
    }
  }

  .picture {
    height: 54vw;
    width: 100%;
    margin-top: 0;
  }
}
</style>
