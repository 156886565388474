<template>
  <div class="about-operandi__wrap">
    <div class="about-operandi">
      <div class="about-operandi__prepend-block" />
      <div v-if="isMobile" class="about-operandi__mobile-first">
        <div class="about-operandi__block about-operandi__title">
          <Text variant="h2">Modus<br /> Operandi</Text>
        </div>
        <div class="about-operandi__block about-operandi__outsourcing">
          <Icon
            name="outsourcing-icon"
          />
          <Text variant="h4">Outsourcing</Text>
          <Text variant="p">The status quo. Your product is designed and delivered to your satisfaction fulfilling the job description with a grace support period.</Text>
        </div>
      </div>
      <template v-else>
        <div class="about-operandi__block about-operandi__title">
          <Text variant="h2">Modus<br /> Operandi</Text>
        </div>
        <div class="about-operandi__block about-operandi__outsourcing">
          <Text variant="h4">Outsourcing</Text>
          <Text variant="p">The status quo. Your product is designed and delivered to your satisfaction fulfilling the job description with a grace support period.</Text>
        </div>
      </template>
      <div class="about-operandi__block about-operandi__management">
        <Icon
          v-if="isMobile"
          name="management-icon"
        />
        <Text variant="h4">Management</Text>
        <Text variant="p">We study and review your model to ensure it falls in line with our skill set, thereby undertaking management and troubleshooting for the production environment</Text>
      </div>
      <div class="about-operandi__block about-operandi__partnership">
        <Icon
          v-if="isMobile"
          name="partnership-icon"
        />
        <Text variant="h4">Partnership</Text>
        <Text variant="p">Your prototype is evaluated to ascertain the business model and the heirarchy of resources required from each party to ensure optimum profitability by design.</Text>
      </div>
    </div>
  </div>
</template>

<script>
import Text from '@/components/UIKit/Text/Text'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import Icon from '@/components/UIKit/Icon'
export default {
  name: 'AboutOperandiSection',
  components: {
    Icon,
    Text
  },
  setup () {
    const {
      isMobile,
      isTablet,
      isDesktop
    } = useBreakpointsMedia()

    return {
      isMobile,
      isTablet,
      isDesktop
    }
  }
}
</script>

<style lang="scss">
.about-operandi {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;

  &__wrap {
    display: flex;
    justify-content: center;
    padding: 132px 136px;
  }
  &__block {
    position: relative;
    z-index: 0;
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;

    h4 {
      font-weight: 700;
      font-size: 45.7143px;
      line-height: 120%;
    }
    p {
      font-weight: 700;
      font-size: 28px;
      line-height: 150%
    }
  }
  &__prepend-block {
    height: 180px;
    width: 5%;
    background-color: map-get($colors, "primary");
  }
  &__mobile-first {
    background-image: url("../../assets/about/operandy-title-mobile-background.svg");
    background-size: cover;
    background-repeat: no-repeat;

    .about-operandi__title {
      background: transparent;
      color: white;
      font-size: 60px;
      line-height: 140%;
      font-weight: 600;
      padding-bottom: 12px;
    }
    .about-operandi__outsourcing {
      background: transparent;
    }
  }
  &__title {
    padding: 152px 117px;
    background-color: black;
    color: white;
    font-weight: 750;
    font-size: 60px;
    line-height: 120%;
    margin-right: 2%;
    min-width: 271px;

    h2 {
      display: block;
    }
  }
  &__outsourcing {
    width: 50%;
    position: relative;
    z-index: 2;
    margin-bottom: -461px;
    margin-right: 5px;
    padding: 315px 86px 245px 86px;
    background-color: map-get($colors, "primary");
    background-image: url("../../assets/about/operandy-outsourcing-background.svg");
    background-size: cover;

    p {
      margin-top: 45px;
    }
  }
  &__management {
    width: 100%;
    height: 840px;
    padding-top: 153px;
    padding-left: 86px;
    margin-right: 5px;
    background-color: #F3F3F3;
    background-image: url("../../assets/about/operandy-management-background.svg");

    p {
      margin-top: 45px;
      max-width: 528px;
    }
  }
  &__partnership {
    width: calc(100% - 33px);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 141px 0;
    margin-left: 40px;
    margin-top: -290px;
    border: 1px solid #BBBBBB;
    background-color: white;

    p {
      max-width: 700px;
    }
  }
  h4 {
    font-size: 48px;
    font-weight: 800;
  }
}

@media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {
  .about-operandi {
    &__wrap {
      padding: 40px 20px;
    }
    &__block {
      h4 {
        font-weight: 700;
        font-size: 36px;
        line-height: 120%;
      }
      p {
        font-size: 21px;
        line-height: 150%;
      }
      .icon {
        display: none;
      }
    }
    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10%;
      width: 30%;
      font-size: 45px;
      line-height: 120%;
    }
    &__outsourcing {
      margin-bottom: -30%;
      margin-right: 5px;
      padding: 22% 86px 15% 86px;

      p {
        margin-top: 30px;
      }
    }
    &__management {
      height: 640px;
      padding-top: 116px;

      p {
        max-width: 420px;
        margin-top: 30px;
      }
    }
    &__partnership {
      padding-top: 108px;
      padding-bottom: 108px;
      margin-top: -220px;

      p {
        max-width: 530px;
      }
    }

    h4 {
      font-size: 48px;
      font-weight: 800;
    }

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      &__outsourcing {
        padding: 192px 80px 170px 80px;
      }
    }

    @media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: 1024px) {
      &__wrap {
        padding: 34px 0 20px;
      }
      &__prepend-block {
        width: 10%;
        height: 235px;
      }
      &__title {
        width: 40%;
        padding: 15% 10%;
        margin-right: 0;
      }
      &__outsourcing {
        padding: 70px 44px 170px 60px;
        margin-right: 0;
        margin-bottom: -23%;
        background-image: url("../../assets/about/operandy-outsourcing-background-tablet.svg");
        background-position: bottom;
      }
      &__management {
        width: 50%;
        height: 560px;
        margin-right: 0;
        padding: 60px 40px;
      }
      &__partnership {
        display: block;
        width: 50%;
        margin: 0;
        padding: 60px 44px 60px 60px;
        z-index: 2;

        h4 {
          margin-bottom: 30px;
        }
      }
      h4 {
        font-size: 32px;
        font-weight: 800;
      }
    }
  }
}

@media screen and (min-width: map-get($mobileLayout, "MinWidth")) and (max-width: map-get($mobileLayout, "MaxWidth")) {
  .about-operandi {
    margin-bottom: 0;

    &__wrap {
      padding: 15px 0 0;
    }

    &__block {
      display: block;
      width: 100%;
      height: unset;
      padding: 52px 34px;
      margin: 0;
      border: none;
      background-size: cover;
      background-position: top;

      h4 {
        margin-bottom: 30px;
        font-size: 22px;
      }
      p {
        font-weight: 500;
        font-size: 16px;
      }
      .icon {
        margin-bottom: 20px;
      }
    }

    &__prepend-block {
      display: none;
    }
    &__title {
      background-color: white;
      font-size: 32px;
      color: black;
      text-align: center;
    }
    &__outsourcing {
      color: white;
      background-image: url("../../assets/about/operandy-outsourcing-background-mobile.svg");
    }
    &__management {
      background-image: url("../../assets/about/operandy-management-background-mobile.svg");
    }
    &__partnership {
      background-image: url("../../assets/about/operandy-partnership-background-mobile.svg");
    }
  }
}
</style>
